import React from 'react'

const Header = ({title, image = "main.jpg"}) => {
  return (
    <div className='relative'>
        <img src={image} className='h-full w-full absolute object-cover -z-10' alt="img" />
    
        <div className='p-16 sm:p-24 flex bg-gray-800 bg-opacity-50 justify-center items-center text-white'>
            <p   className='text-4xl sm:text-6xl py-10 font-black'>{title}</p>
        </div>
    </div>
  )
}

export default Header