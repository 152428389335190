import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AboutUs from './pages/about';
import ScrollToTop from './components/ScrollToTop';
import Testimonial from './pages/testimonial';
import FAQ from './pages/faq';
import Contact from './pages/contact';
import Join from './pages/join';

const router = createBrowserRouter([
  {
    path : "/",
    element : <ScrollToTop/>,
    children : [
      {
        index : true,
        element: <App/>,
      },
      {
        path: "/about",
        element: <AboutUs/>,
      },
      {
        path: "/testimonial",
        element: <Testimonial/>,
      },
      {
        path: "/faq",
        element: <FAQ/>,
      },
      {
        path: "/join",
        element: <Join/>,
      },
      {
        path: "/contact",
        element: <Contact/>,
      },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

