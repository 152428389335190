import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'

const FAQ = () => {
  const faqs = [
    {
      question: "What is the minimum age that the home can accommodate?",
      answer: "BGGM Limited caters for adults aged 18 and older."
    },
    {
      question: "How many rooms does the home have?",
      answer: "The home has 5 bedrooms."
    },
    {
      question: "Are pets allowed?",
      answer: "We do not currently allow pets in our home."
    },
    {
      question: "How does the home deal with the challenges of learning disability - Autism?",
      answer: "At BGGM Limited, we prioritize understanding each individual living with learning disability - Autism and supporting them to live meaningful lives. Our experienced and well-trained care team follows a person-centered care philosophy, focusing on seeing the person first and the learning disability second. This approach allows us to provide tailored support that respects each resident's unique perspective and needs."
    },
    {
      question: "Does the home support wheelchair access?",
      answer: "Yes, the home is equipped with facilities to facilitate wheelchair access, making it easy for residents to navigate around the premises."
    },
    {
      question: "When is visiting allowed?",
      answer: "Visitors are welcome throughout the day, with residents enjoying their mealtimes undisturbed. Visitors can meet residents in various areas of the home, including the lounge, garden, or the resident's own bedroom. Residents are also encouraged to go out with visitors as they please."
    },
    {
      question: "How does the home keep in contact with relatives who are unable to visit?",
      answer: "BGGM Limited keeps relatives informed through monthly newsletters and biannual meetings to discuss the home's plans and address any concerns or questions."
    },
    {
      question: "Do I have a say in the running of the home?",
      answer: "Yes, residents are encouraged to actively participate in decision-making processes regarding their care and the overall running of the home. BGGM Limited prioritizes a person-centered approach, ensuring that each resident's preferences and needs are respected and supported."
    },
    {
      question: "To what extent can I maintain independence?",
      answer: "Residents at BGGM Limited are encouraged to maintain as much independence as possible. The home respects each resident's autonomy and supports them in making choices about their daily activities, meals, and hobbies. With 24-hour support available, residents can relax and enjoy their time at BGGM with peace of mind."
    },
    {
      question: "Is smoking permitted at BGGM Limited?",
      answer: "To ensure the safety and well-being of all residents, smoking facilities are not available at BGGM Limited. We promote an active, healthy lifestyle for all our residents."
    },
    {
      question: "What measures do you implement to ensure quality assurance at the home?",
      answer: "Internal reviews are completed monthly by our home Manager who ensures standards of care are actively managed. We also undertake surveys with residents, relatives, friends, and professionals such as GP’s and District nurses and we respond to those surveys in our home’s development plan."
    }
  ];

  return (
    <div>
        <Navbar/>
        <Header
        title={"FAQs"}
        image='service7.jpg'/>

        <div className='sm:p-24 bg-gray-100 px-5 py-16 space-y-5 font-[Figtree]' id='gallery'>
           {faqs.map((faq, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left text-bg bg-bg/10 rounded-lg hover:bg-bg/50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>{faq.question}</span>
                <ChevronUpIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 ">
                {faq.answer}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
        </div>
        <Footer/>
    </div>
  )
}

export default FAQ