import React from 'react'
import { Fade } from 'react-reveal'

const Mission = () => {
  return (
    <div className='sm:p-24 bg-[#1C244B] px-5 py-16 space-y-10 text-white' id='gallery'>
        <div className=' text-center space-y-5'>
            <p className='text-4xl' >Embracing Community</p>
            <p>Residents have access to local recreational facilities in our serene neighbourhood.</p>
        </div>
    
        <div className='grid grid-cols-2 sm:grid-cols-3 gap-2'>
            <Fade bottom>
            <img src="/image8.jpg" className='object-cover h-full rounded-lg w-full' alt="" />
            </Fade>
            <Fade bottom>
            <img src="/image9.jpg" className='object-cover h-full rounded-lg w-full' alt="" />
            </Fade>
            <Fade bottom>
            <img src="/image10.jpg" className='object-cover h-full rounded-lg w-full' alt="" />
            </Fade>
            <Fade bottom>
            <img src="/image11.jpg" className='object-cover h-full rounded-lg w-full object-cover' alt="" />
            </Fade>
            <Fade bottom>
            <img src="/image12.jpg" className='object-cover h-full rounded-lg w-full' alt="" />
            </Fade>
            <Fade bottom>
            <img src="/image13.jpg" className='object-cover h-full rounded-lg w-full' alt="" />
            </Fade>
        </div>
    </div>
  )
}

export default Mission