import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'

const AboutUs = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"About Us"}
        image='service5.jpg'/>

        <div className='bg-white px-5 py-16 sm:p-24'>
          <p>
            Welcome to BGGM Limited Supported Living! A beautiful care home set in the heart of West midlands, Birmingham
            <br /><br />
            At BGGM, we believe that everyone deserves a home they can feel proud of. Our supported living goes beyond just providing modern and homely surroundings, it is person centered. It's about offering support that fits seamlessly around you, fostering uniquely rewarding relationships that will help build your confidence and independence.
            <br /><br />We specialize in providing comprehensive care and support services for adults looking to live fulfilling and independent lives. With years of experience in the field of adult care, our dedicated team is committed to promoting well-being, dignity, and choice for everyone we serve.
            <br /><br />We understand that each person is unique, with their own set of needs and aspirations. That's why we take a personalized approach to care, tailoring our support services to meet the specific needs and goals of each resident. Whether it's assistance with daily tasks, social activities, or emotional support, we're here to provide the support you need to thrive.
            <br /><br />At BGGM Limited Supported Living, we're more than just a care provider – we're your partner in building a fulfilling and independent life. Let us support you on your journey towards greater well-being and independence.
          </p>
        </div>

        <div className='sm:p-24 bg-gray-100 px-5 py-16 space-y-10' id='gallery'>
            <div className=' text-center space-y-5'>
                <p className='text-4xl font-black' >Meet Our Team</p>
            </div>


          <div className='grid sm:grid-cols-3 gap-4'>
            <div className='border'>
              <img src="/bukola.png" className='w-full object-cover h-72' alt="" />

              <div className='p-4 space-y-2'>
                <p className='text-lg font-semibold'>Ms Bukola Adewuyi</p>
                <p className='font-bold text-sm'>The Registered Manager</p>
                <p className='text-xs'>Ms. Bukola Adewuyi, the Registered Manager, boasts 26 years of extensive experience in Nursing and Care practice. Renowned for her selflessness and exceptional teamwork, she is a proficient communicator and dedicated nurse with a fervent commitment to practicing holistic nursing and social work care. <br /> With expertise in conducting assessments and adeptly developing, implementing, and evaluating individualized care plans, Ms. Adewuyi ensures that service users, patients, and their families are treated with the utmost respect as outlined in the scope of health and social care regulations. Holding qualifications as a Registered Nurse (RN) with a Diploma in Nursing, she brings a wealth of knowledge and skill to her role.</p>
              </div>  
            </div>
            <div className='border'>
              <img src="/joseph.jpg" className='w-full object-cover h-72' alt="" />

              <div className='p-4 space-y-2'>
                <p className='text-lg font-semibold'>Mr Joseph Samuel</p>
                <p className='font-bold text-sm'>Manager</p>
                <p className='text-xs'>Mr Joseph Samuel has over 8 years of experience in the social care sector, He had focused on supporting elderly and vulnerable individuals, including those with learning disability - Autism, challenging behaviours, physical disabilities, learning disabilities, and autism. As a support worker, He provided care in various settings, such as nursing homes, client residences, and residential care facilities. Additionally, He has extensive experience in human resources, overseeing staff training, pay and benefits policies, employee counselling, payroll, contracts, recruitment, employee relations, compliance with employment law, rewards, and benefits schemes, learning and development activities, and performance management.</p>
              </div>  
            </div>
            <div className='border'>
              <img src="/olushola.png" className='w-full object-cover h-72' alt="" />

              <div className='p-4 space-y-2'>
                <p className='text-lg font-semibold'>Ms Olushola Apakala</p>
                <p className='font-bold text-sm'>Care Coordinator</p>
                <p className='text-xs'>Bringing together experience from both the Health and Social Care sectors and the Hospitality Industry, Ms. Olushola Akpakala has held roles as both a Care Home Manager and a Hotel Services Consultant. In her capacity, she will collaborate with the registered manager to oversee the residents' well-being, ensuring their physical, emotional, and social needs are addressed. Proficient in leadership, motivation, and mentorship, she has effectively guided various care teams to ensure the facility operates smoothly, safely, and efficiently. She holds a strong passion for Health and Social Care, with a particular emphasis on Supported Living and Domiciliary Care Service.</p>
              </div>  
            </div>
          </div>
        </div>
        <Footer/>
    </div>
  )
}

export default AboutUs