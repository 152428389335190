import { EnvelopeIcon, MapIcon, PhoneIcon } from '@heroicons/react/24/solid'
import React from 'react'

const Footer = () => {
  return (
    <>
    <div className='sm:p-24 px-5 py-16 bg-[#1C244B] grid sm:grid-cols-3 font-light text-sm text-gray-200 gap-20'>

        <div className='space-y-5'>
            <div className='flex gap-4 items-center'>
                {/* <img src="logo2.png" className='h-28' alt="" /> */}
                <p className='text-3xl font-bold text-white'>BGGM Limited Supported Living</p>
            </div>
            <p className='text-gray-400'>We believe that everyone deserves a home they can feel proud of. But our supported living goes beyond just providing modern, homely surroundings. It's about offering support that fits seamlessly around you, fostering uniquely rewarding relationships that will help build your confidence and independence.</p>            
        </div>

        <div className='space-y-10' id='contact'>
            <p className='font-bold text-2xl inline border-b border-yellow-400 text-white'>Contact Us</p>

            <div className='space-y-5'>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <PhoneIcon className='h-4'/>
                    <span><a className='underline' href="tel:+44 (0) 1215729718">+44 (0) 1215729718</a></span>
                </div>
                <div className='flex  items-center gap-4 hover: text-white'>
                    <EnvelopeIcon className='h-4'/>
                    <a className='underline' href='mailto:info@bggm.co.uk'>info@bggm.co.uk</a>
                </div>
                <div className='flex items-start gap-4'>
                    <MapIcon className='h-4 flex-shrink-0'/>
                    <span>109 Javelin Avenue B35 7LH
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div className='bg-gray-800 py-4 text-center'>
        <p className='text-sm text-gray-500'>&copy; 2022. Designed by Peachy Inc.</p>
    </div>
    </>
  )
}

export default Footer