import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const Hero = () => {
  const options = {
    type         : 'loop',
    gap          : '1rem',
    autoplay     : true,
    pauseOnHover : false,
    resetProgress: false,
  };

  return (
    <Splide options={options} aria-label="My Favorite Images">
      <SplideSlide>
        <div className='relative w-full h-96 sm:h-screen'>
          <img src="/image1.jpg" alt="Image 1" className='absolute h-full w-full z-10 object-cover'/>
          <div className='bg-gray-500/80 text-white w-full h-full z-20 relative flex flex-col gap-2 justify-center items-center sm:p-20 p-10 text-center'>
            <p className='text-2xl sm:text-6xl font-bold'>Welcome To BGGM Limited Supported Living</p>
            <p className='sm:text-xl'>We believe that everyone deserves a home they can feel proud of</p>
            <a href='/contact' className='sm:text-lg bg-blue-800 px-5 py-2 rounded text-xs'>Contact Us</a>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
      <div className='relative w-full h-96 sm:h-screen'>
          <img src="/service2.jpg" alt="Image 1" className='absolute h-full w-full z-10 object-cover'/>
          <div className='bg-gray-500/80 text-white w-full h-full z-20 relative flex flex-col gap-2 justify-center items-center sm:p-20 p-10 text-center'>
            <p className='text-2xl sm:text-6xl font-bold'>Welcome To BGGM Limited Supported Living</p>
            <p className='sm:text-xl'>We believe that everyone deserves a home they can feel proud of</p>
            <a href='/contact' className='sm:text-lg bg-blue-800 px-5 py-2 rounded text-xs'>Contact Us</a>
          </div>
        </div>      
      </SplideSlide>
      <SplideSlide>
      <div className='relative w-full h-96 sm:h-screen'>
          <img src="/join.jpg" alt="Image 1" className='absolute h-full w-full z-10 object-cover'/>
          <div className='bg-gray-500/80 text-white w-full h-full z-20 relative flex flex-col gap-2 justify-center items-center sm:p-20 p-10 text-center'>
            <p className='text-2xl sm:text-6xl font-bold'>Be A Part Of BGGM Limited Supported Living</p>
            <p className='sm:text-xl'>We believe that everyone deserves a home they can feel proud of</p>
            <a href='/join' className='sm:text-lg bg-blue-800 px-5 py-2 rounded text-xs'>Join Us</a>
          </div>
        </div>      
      </SplideSlide>
    </Splide>
  )
}

export default Hero


