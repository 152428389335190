import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'

const Join = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"Join Us"}
        image='join.jpg'/>

        <div className='sm:p-24 bg-gray-100 px-5 py-16 space-y-10' id='gallery'>
          <p className='text-center'>
            We invite individuals to join our team for a variety of reasons; some are motivated by a deep passion for caregiving, while others aim to contribute their expertise to the advancement of care services. 
            <br /><br />
            Our approach to care nurtures an environment where staff can flourish and reach their full potential. We offer a wide range of roles, including managerial positions, junior and senior caregiving roles, catering staff, activity coordinators, and housekeeping personnel. 
            <br /><br />
            Through comprehensive training and mentorship programs, we ensure that our team members are equipped with the necessary skills to deliver exceptional care, recognizing their central role in the well-being of our residents and their families. 
            <br /><br />
            Our commitment to excellence relies on our ability to attract and retain top talent. Success in the healthcare field demands individuals who can drive initiatives and prioritize the needs of our residents. 
            <br /><br />
            If you're interested in joining our dedicated team, please complete the contact form on our contact us page and provide a brief overview of your experience. 
            <br /><br />
            We eagerly await your response! 
            <br /><br />
            Many thanks.
          </p>
        </div>
        <Footer/>
    </div>
  )
}

export default Join