import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'

const Contact = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"Contact Us"}
        image='conct.jpg'/>

        <div className='sm:p-24 bg-gray-100 px-5 py-16 space-y-5 font-[Figtree]' id='gallery'>
          <div className='grid sm:grid-cols-2 gap-4'>
            <form action="" className='space-y-4'>
              <p className='text-6xl font-black'>Get In Touch</p>
              <div className='flex flex-col gap-4'>
                <input type="text" className='p-4' placeholder='Your name' />
                <input type="text" className='p-4' placeholder='Email' />
                <textarea type="text" className='p-4' placeholder='Message'></textarea>
                <button className='bg-bg py-4 text-white'>Submit</button>
              </div>
            </form>

            <div className='bg-white p-5 divide-y'>
              <p className='text-3xl font-bold py-5'>Contact Info</p>
              <div className='py-5'>
                <p>Mobile</p>
                <a className='text-bg' href='tel:+44 (0) 1215729718'>+44 (0) 1215729718</a>
              </div>
              <div className='py-5'>
                <p>Email</p>
                <a className='text-bg' href='mailto:info@bggm.co.uk'>info@bggm.co.uk</a>
              </div>
              <div className='py-5'>
                <p>Address</p>
                <a className='text-bg' href='#'>109 Javelin Avenue B35 7LH</a>
              </div>
            </div>
          </div>
        </div>

        <Footer/>
    </div>
  )
}

export default Contact