import { CheckIcon } from '@heroicons/react/24/solid';
import React from 'react'
import Fade from 'react-reveal/Fade'
const Facilities = () => {
    const accommodationList = [
        "Toilets/Bathroom",
        "Kitchen",
        "Dinning",
        "Lounges",
        "Wheelchair accessible garden",
        "Wi-Fi"
      ];
      
      const activitiesList = [
        "Arts and crafts",
        "Baking",
        "Coffee mornings",
        "Gardening",
        "Light exercise",
        "Live entertainment",
        "Games",
        "Workshops",
        "Virtual tours",
        "Community engagement"
      ];
      
      const careAndSupportList = [
        "Learning Disability - Autism",
        "Autism",
        "Hairdressing",
        "Home cooked meals",
        "Physiotherapy",
        "Medication Administering",
        "Respite Care",
        "Therapy",
        "Social engagement"
      ];
      
      const proximityDestinationsList = [
        "Eden court medical Practice",
        "Lloyds Pharmacy",
        "Farnborough road bus route",
        "Castle Vale Swimming pool",
        "Castle vale shopping centre",
        "Castle vale Stadium"
      ];

      
  return (
    <div className='sm:p-28 px-5 py-16 bg-gray-100 text-black'>
        <div className='sm:w-3/4  mx-auto pb-16 space-y-4 text-center'>
            <Fade bottom>
            <p   className='sm:text-5xl text-3xl text-center font-bold'>Our Facilities</p>
            </Fade>
        </div>

         <div className='grid sm:grid-cols-4 gap-4'>
            <Card title="Accommodation" list={accommodationList} />
            <Card title="Activities" list={activitiesList} />
            <Card title="Care and Support" list={careAndSupportList} />
            <Card title="Proximity Destinations to the Residence" list={proximityDestinationsList} />
         </div>
    </div>
  )
}


const Card = ({ title, text, list }) => (
    <div className='border flex flex-col justify-between rounded '>
      <div className='py-5 px-2 space-y-5'>
        <p className='text-xl text-bg' style={{ fontFamily: "Croissant One" }}>{title}</p>
        <p className='text-sm'>
          {text}
        </p>
        <ul className='pl-5 space-y-1'>
          {list.map((item, index) => (
            <li key={index} className='text-sm flex items-center gap-2 capitalize'>
              <CheckIcon className='h-4 w-4 bg-bg text-white rounded-full p-0.5'/> {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
  
export default Facilities