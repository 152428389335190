import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, ComputerDesktopIcon, HomeIcon, MapPinIcon, PhoneIcon, QuestionMarkCircleIcon, SunIcon, UserIcon, UserPlusIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Dialog } from '@headlessui/react'
import { ChartBarIcon, ChartBarSquareIcon, DocumentDuplicateIcon, EnvelopeIcon, FlagIcon, FolderIcon, LockOpenIcon, MoonIcon, UserGroupIcon, WindowIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'About Us', href: '/about', current: false },
  { name: 'Testimonial', href: '/testimonial', current: false },
  { name: 'FAQ', href: '/faq', current: false },
  { name: 'Join Us', href: '/join', current: false },
  { name: 'Contact Us', href: '/contact', current: false },
]

const menu = [
    {
      icon: HomeIcon,
      title: "Home",
      href: "/"
    },
    {
      icon: DocumentDuplicateIcon,
      title: "About Us",
      href: "/about"
    },
    {
      icon: UsersIcon,
      title: "Testimonial",
      href: "/testimonial"
    },
    {
      icon: QuestionMarkCircleIcon,
      title: "FAQ",
      href: "/faq"
    },
    {
      icon: DocumentDuplicateIcon,
      title: "Join Us",
      href: "/join"
    },
    {
      icon: PhoneIcon,
      title: "Contact Us",
      href: "/contact"
    }
  ];
  
  console.log(menu);
  
const Navbar = ({darkmode, setDarkmode, setLanguage, language}) => {
    const [scrolling, setScrolling] = useState(false);

  // Add a scroll event listener to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

    const [open, setOpen] = useState(false)
    return(
        <>
            <div className='sticky top-0 z-20 w-full font-[Figtree]'>
                <div className={`relative flex h-20 items-center bg-white px-5 sm:px-20 justify-between`}>
                        <div className="flex flex-1 text-bg font-semibold items-center justify-between">
                            <div className="flex items-center">
                              {/* <img src="logo.png" className='h-20' alt="" /> */}
                              <span className='text-3xl text-bg'>BGGM SLDC</span>
                            </div>
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-8">                    
                                    {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={'text-bg hover:bg-bg hover:text-white hover:text-bg rounded-md px-3 py-2 text font-medium'}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </a>
                                    ))}
                                </div>  
                            </div>
                            <div className="inset-y-0 sm:hidden left-0 flex items-center p-2" onClick={() => setOpen(!open)}>
                                <Bars3Icon className="block h-6 w-6 text-bg" aria-hidden="true" />
                            </div>
                        </div>
                </div>
            </div>
            <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-blue-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="-translate-x-full"
                        enterTo="-translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="-translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto relative w-screen max-w-xs">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="mx-auto px-5">
                            </div>



                        </Transition.Child>
                        <div className="flex h-full font-[Figtree] flex-col overflow-y-scroll bg-[#1C244B] text-bg shadow-xl">
                            <div className="">
                            <Dialog.Title className="text-base font- leading-6">
                                
                                <div className='border-b'>
                                    {/* <img src="logo.png" alt="lgo" className='h-28 mx-auto p-5' /> */}
                                    <p className="text-white text-2xl text-center py-4">BGGM SLDC</p>
                                </div>

                                <div className='p-5'>
                                    {
                                        menu.map(({icon:Icon, title, href}) => (
                                            <a href={href}>
                                            <div className='flex gap-4 pr-4 text-white  font-600 px-4 py-4'>
                                                <Icon className='h-5'/>
                                                <span className='text-sm'>{title}</span>
                                            </div>                                            
                                            </a>

                                        ))
                                    }
                                </div>

                            </Dialog.Title>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">{/* Your content */}</div>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
            </Transition.Root>        
        </>

  )
}

export default Navbar;

