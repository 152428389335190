import React from 'react'
import Fade from 'react-reveal/Fade'
const Qualities = () => {
  return (
    <div className='sm:p-28 px-5 py-16 bg-blue-200 text-black'>
        <div className='sm:w-3/4  mx-auto pb-16 space-y-4 text-center'>
            <Fade bottom>
            <p   className='sm:text-5xl text-3xl text-center font-bold'>Our Services</p>
            <p className='text-sm sm:text-md'>Services That Enhance Quality of Life</p>
            </Fade>
        </div>

         <div className='grid sm:grid-cols-4 gap-4'>
            <Fade bottom>
            <Card
            title={"Housing Solutions"}
            text={"Our housing options are designed to create a comfortable and secure environment where adults can thrive and enjoy their independence."}
            img={"service1.png"}/>
            </Fade>
            <Fade bottom>
            <Card
            title={"Personal Care Assistance"}
            text={"Our caregivers provide compassionate personal care assistance, ensuring that individuals receive the support they need while maintaining their dignity."}
            img={"service2.jpg"}/>                
            </Fade>
            <Fade bottom>
            <Card
            title={"Health And Wellness"}
            text={"Our programs focus on maintaining and enhancing physical and mental well-being, providing resources for a healthy and vibrant life."}
            img={"service3.jpg"}/>
            </Fade>
            <Fade bottom>
            <Card
            title={"Skill Enhancement"}
            text={"We offer opportunities for skill development and learning, empowering adults to continue growing and exploring new interests. "}
            img={"service4.jpg"}/>
            </Fade>
         </div>
    </div>
  )
}


const Card = ({title, text, img}) => (
    <div className='bg-white flex flex-col justify-between rounded shadow'>
        <div className='py-5 px-2 space-y-5'>
            <p className='text-2xl text-bg'  >{title}</p>
            <p className='text-sm'>
                {text}
            </p>
        </div>
        <img src={img} className='w-full h-48 object-cover' alt="" />
    </div>
)
export default Qualities