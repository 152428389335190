import React from 'react'
import { Link } from 'react-router-dom/dist'
import Fade from 'react-reveal/Fade'
const About = () => {
  return (
    <div className='sm:p-20 px-5 py-16 gap-10 bg-white grid sm:grid-cols-2'>
        <div className='sm:p-10'>
            <Fade bottom>
                <img src="bags.jpg" className='w-full' alt="" />
            </Fade>
        </div>
        <div className='sm:p-10 space-y-5'>

            <div className='py-4 space-y-4'>
            <Fade bottom>
                    <p className='text-blue-800 font-semibold text-2xl'>About BGGM Limited Supported Living</p>
                </Fade>
                
                <Fade bottom>
            <p> 
                We believe that everyone deserves a home they can feel proud of. But our supported living goes beyond just providing modern, homely surroundings. It's about offering support that fits seamlessly around you, fostering uniquely rewarding relationships that will help build your confidence and independence.
            </p>
            <p>
                BGGM Supported Living specializes in providing comprehensive care and support services for adults looking to live fulfilling and independent lives. With years of experience in the field of adult care, our dedicated team is committed to promoting well-being, dignity, and choice for each individual we serve.
            </p>
                </Fade>

            </div>

            <Link to={"/about"}>
                <button className='bg-bg text-white px-8 py-3 text-lg shadow rounded'>Learn More</button>
            </Link>
        </div>
    </div>
  )
}

export default About