import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Header from '../components/header'

const Testimonial = () => {
  return (
    <div>
        <Navbar/>
        <Header
        title={"Testimonials"}
        image='service6.jpg'/>

        <div className='sm:p-24 bg-gray-100 px-5 py-16 space-y-10' id='gallery'>
          <p className='text-center'>We take immense pride in the quality of care we offer, and it warms our hearts when families and friends of our residents express their appreciation. You can explore a selection of these heartfelt reviews below.</p>
          <div className='grid sm:grid-cols-3 gap-4'>
            <div className='space-y-4 border p-5'>
              <p className='text-lg'>
                I'm so grateful for BGGM Supported Living. They've helped me regain my independence and enjoy life to the fullest.
              </p>
              <div className='flex items-center gap-4'>
                <img src="/client.png" className='h-12' alt="" />

                <p>Lisa. S</p>
              </div>
            </div>
            <div className='space-y-4 border p-5'>
              <p className='text-lg'>
              The care and attention I receive from BGGM Supported Living have made a tremendous difference in my life. I feel supported and valued every step of the way.
              </p>
              <div className='flex items-center gap-4'>
                <img src="/client.png" className='h-12' alt="" />

                <p>David. M</p>
              </div>
            </div>
            <div className='space-y-4 border p-5'>
              <p className='text-lg'>
                BGGM Supported Living has truly transformed my life. Their personalized care and support have given me the confidence to embrace my independence and pursue my passions.orted Living has truly transformed my life. Their personalized care and support have given me the confidence to embrace my independence and pursue my passions.              
              </p>
              <div className='flex items-center gap-4'>
                <img src="/client.png" className='h-12' alt="" />

                <p>Alexander. D</p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Testimonial